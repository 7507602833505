<template>
  <globalNavigation
    :navData="navData"
    :navExpanded="navExpanded"
    @toggle-nav="handleToggleNav"
  />
</template>

<script>
/**
 * @fileoverview Side Navigation
 * Relies on vuex for mapping state variables.
 *
 * @author Cameron Bulock <cameron.bulock@quavo.com>
 */

import { mapGetters } from "vuex";

import globalNavigation from "@/qux-common/qux22/components/organisms/global-navigation";

export default {
  components: {
    globalNavigation,
  },
  computed: {
    navData() {
      const navData = [
        {
          name: "",
          links: [
            {
              name: "Home",
              href: "/",
              icon: "$home",
            },
            {
              name: "Tasks",
              icon: "$queue",
              links: [...this.tasksToNav],
            },
          ],
        },
      ];
      return navData;
    },
    sortedTaskSummary() {
      if (this.taskSummary && Array.isArray(this.taskSummary)) {
        return this.taskSummary
          .slice()
          .sort((a, b) => a.TaskLabel.localeCompare(b.TaskLabel));
      } else {
        return [];
      }
    },
    tasksToNav() {
      return this.sortedTaskSummary.map((task) => {
        return {
          name: task.TaskLabel,
          href: `/task/${task.TaskLabel}`,
          badge: task.TaskCount,
        };
      });
    },
    ...mapGetters({
      activeClientId: "getActiveClientId",
      navExpanded: "getNavExpanded",
      taskSummary: "getTaskSummary",
    }),
  },
  methods: {
    handleToggleNav({ expanded, name }) {
      if (name === "Tasks" && expanded) {
        this.retrieveTaskSummary();
      }
    },
    retrieveTaskSummary() {
      this.$store.dispatch("retrieveTaskSummary", {
        ClientId: this.activeClientId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation-wrapper {
  grid-area: sidenav;
  &::v-deep {
    .nav-group .nav-link .v-btn--active.is-active-nav.v-btn--text {
      background-color: $qvo-color-blue-400;
      .v-btn__content .label {
        color: $qvo-color-grey-000;
      }
    }
  }
}
</style>